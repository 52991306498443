import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import { useLocation } from '@reach/router';
import firebase from 'gatsby-plugin-firebase';
import { setLoginTokens } from '../../utils/auth';
import { LoginBusUser } from '../../queries';
import { isEmail } from '../../utils/validators';
import { Container } from '../../components/Shared/Shared';
import { InputWrapper, FormInput, FormLabel, FormError, PageLogo } from '../../components/Shared/Forms/Forms';
import {
  PageContainer,
  FormWrapper,
  FormHeaderWrapper,
  FormHeaderLabel,
  LoginFormButtonsContainer,
  FormSubmitButton,
  FormSection,
  PageCR,
  PageFooter,
  SecondCta
} from '../../components/Shared/Forms/Forms';
import useIcons from '../../hooks/useIcons';

const LoginForm = ({ onSubmitHandler, loading, error }) => {
  const location = useLocation();
  const icons = useIcons();
  const queryParams = new URLSearchParams(location.search);
  const { control, handleSubmit, errors } = useForm();
  const onChange = args => ({ value: args[0].target.value });
  const onSubmit = handleSubmit(variables => onSubmitHandler({ variables, fetchPolicy: 'no-cache' }));

  const goToResetPassword = () => {
    navigate('/account-recovery', { replace: true });
  };

  return (
    <PageContainer>
      <PageLogo alt={'Collar Logo'} image={icons.logoLarge.childImageSharp.gatsbyImageData} />
      <FormWrapper display={'flex'} flexDirection={'column'} maxWidth={480} width={'480px'}>
        <FormSection alignItems={'center'}>
          <FormHeaderWrapper>
            <FormHeaderLabel margin={'32px 0 32px 0'}>Sign In</FormHeaderLabel>
          </FormHeaderWrapper>
          <Container>
            <InputWrapper>
              <FormLabel error={errors.email}>Email</FormLabel>
              <Controller
                as={<FormInput error={errors.email} type={'email'} />}
                control={control}
                name={'email'}
                onChange={onChange}
                defaultValue={queryParams?.get?.('email') || ''}
                rules={{
                  required: true,
                  pattern: { value: isEmail, message: 'Wrong Email Address' }
                }}
              />
              {errors.email && <FormError>{errors.email.message || 'Email is required'}</FormError>}
            </InputWrapper>
            <InputWrapper>
              <FormLabel error={errors.password}>Password</FormLabel>
              <Controller
                as={<FormInput type={'password'} error={errors.password} />}
                control={control}
                name={'password'}
                onChange={onChange}
                defaultValue={''}
                rules={{ required: true, minLength: 8 }}
              />
              {errors.password && <FormError>{errors.password.message || 'Password is required'}</FormError>}
            </InputWrapper>
          </Container>
          <LoginFormButtonsContainer>
            <FormSubmitButton onClick={onSubmit} error={error} loading={loading}>
              Login
            </FormSubmitButton>
            <SecondCta onClick={goToResetPassword}>Forgot Password</SecondCta>
          </LoginFormButtonsContainer>
        </FormSection>
      </FormWrapper>
      <PageFooter>
        <PageCR>Copyright © {new Date().getFullYear()} Collar. All Rights Reserved</PageCR>
      </PageFooter>
    </PageContainer>
  );
};

const LoginView = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [onUserLogin, { data: { loginBusUser: tokens } = {}, loading, error }] = useMutation(LoginBusUser, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (tokens) {
      setAuthLoading(true);
      setLoginTokens(tokens);
      firebase
        ?.auth?.()
        .signInWithCustomToken(tokens.firebaseToken)
        .then(() => navigate('/app', { replace: true }))
        .finally(() => setAuthLoading(false));
    }
  }, [tokens]);

  return <LoginForm onSubmitHandler={onUserLogin} loading={loading || isAuthLoading} error={error} />;
};

export default LoginView;
